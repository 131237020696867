import { SHARED_UTILS, state } from ":mods";
import { useNavigate } from "@solidjs/router";
import { createEffect, createRenderEffect, onCleanup, onMount, splitProps } from "solid-js";

const duration_speed = 1000; // default 1000 -> higher is faster counter
export function NotFoundWithRedirect(props: { path: string; base?: string; duration?: number }) {
  const [local] = splitProps(props, ["base", "duration", "path"]);
  const duration = local.duration ?? 1000;
  const $timer = state.create(Math.floor(duration / duration_speed));
  // const $timer_listener = $timer.derive()
  const navigate = useNavigate();
  let redirected = false;
  createRenderEffect(() => {
    const value = $timer.value;
    const current_pathname = window.location.pathname;
    setTimeout(() => {
      if (value <= 1) {
        if (redirected) {
          return;
        }
        redirected = true;
        const path = SHARED_UTILS.populateRouteHref({ base: local.base, path: local.path });
        // console.log("redirecting activated ", path);
        // navigate(path);
        const active_pathname = window.location.pathname;
        if (active_pathname !== current_pathname || path === active_pathname) {
          return;
        }
        navigate(path, { replace: true });
      } else {
        $timer.set((s) => s - 1);
      }
    }, duration);
  });
  return (
    <div class="flex flex-col w-full min-h-full items-center justify-center">
      <h1>Error 404: Page Not Found</h1>
      <p>You will be redirected to the nearest page in {$timer.value.toFixed(0)} seconds...</p>
    </div>
  );
}
